const ENDPOINTS = {
  LOGIN: "/login",
  LOG_OUT: "/logout",
  USERS: "/users",
  COMPANIES: "/companies",
  DEPARTMENTS: "/departments",
  POSITIONS: "/positions",
  ROLES: "/roles",
  PASSWORD: "/password",
  SESSION: "/session",
  MEETINGS: "/meetings",
  WORKFLOWS: "/workflows",
  NOTICES: "/notices",
  COMMENTS: "/comments",
  FILES: "/files",
  MEETING_STATUS: "/meetings/status",
};

export default ENDPOINTS;
