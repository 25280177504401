import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const HouseIcon = (props: SvgIconProps): React.ReactElement => (
  <SvgIcon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.9993 25.9991V19.999C18.9993 19.7337 18.8939 19.4794 18.7064 19.2919C18.5188 19.1043 18.2645 18.999 17.9993 18.999H13.9993C13.7341 18.999 13.4797 19.1043 13.2922 19.2919C13.1046 19.4794 12.9993 19.7337 12.9993 19.999V25.9991C12.9993 26.2643 12.8939 26.5186 12.7064 26.7061C12.5189 26.8937 12.2646 26.9991 11.9994 26.9991L6.00012 26.9999C5.86879 26.9999 5.73874 26.974 5.6174 26.9238C5.49606 26.8735 5.38581 26.7999 5.29294 26.707C5.20007 26.6142 5.12639 26.5039 5.07613 26.3826C5.02587 26.2613 5 26.1312 5 25.9999V14.4423C5 14.303 5.02911 14.1652 5.08547 14.0378C5.14183 13.9104 5.22418 13.7962 5.32726 13.7024L15.3266 4.6106C15.5106 4.44323 15.7505 4.35049 15.9993 4.35048C16.2481 4.35047 16.4879 4.4432 16.672 4.61055L26.6727 13.7024C26.7758 13.7962 26.8582 13.9104 26.9145 14.0378C26.9709 14.1652 27 14.303 27 14.4424V25.9999C27 26.1312 26.9741 26.2613 26.9239 26.3826C26.8736 26.5039 26.7999 26.6142 26.7071 26.707C26.6142 26.7999 26.5039 26.8736 26.3826 26.9238C26.2613 26.974 26.1312 26.9999 25.9999 26.9999L19.9991 26.9991C19.7339 26.9991 19.4796 26.8937 19.2921 26.7062C19.1046 26.5186 18.9993 26.2643 18.9993 25.9991V25.9991Z"
      stroke="#C4C4C4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);

export default HouseIcon;
