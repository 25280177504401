import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const UsersIcon = (props: SvgIconProps): React.ReactElement => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11 17C14.5899 17 17.5 14.0899 17.5 10.5C17.5 6.91015 14.5899 4 11 4C7.41015 4 4.5 6.91015 4.5 10.5C4.5 14.0899 7.41015 17 11 17Z"
      stroke="#C4C4C4"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M19.4268 4.24215C20.3208 3.99026 21.2584 3.93287 22.1765 4.07386C23.0945 4.21486 23.9717 4.55095 24.749 5.0595C25.5262 5.56805 26.1855 6.23726 26.6823 7.02205C27.1791 7.80683 27.502 8.68898 27.6292 9.60906C27.7564 10.5291 27.685 11.4658 27.4197 12.3559C27.1544 13.2461 26.7014 14.069 26.0913 14.7694C25.4812 15.4697 24.7281 16.0312 23.8827 16.416C23.0373 16.8008 22.1193 16.9999 21.1905 17"
      stroke="#C4C4C4"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.99951 21.6746C3.01468 20.2306 4.36238 19.052 5.92882 18.2384C7.49527 17.4248 9.23448 17.0001 10.9996 17C12.7648 16.9999 14.504 17.4246 16.0705 18.238C17.637 19.0515 18.9848 20.23 20.0001 21.6739"
      stroke="#C4C4C4"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.1904 17C22.9557 16.9987 24.6953 17.4228 26.262 18.2364C27.8286 19.05 29.1761 20.2291 30.1905 21.6739"
      stroke="#C4C4C4"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default UsersIcon;
