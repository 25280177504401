// *** Пример использования useAppContext и actions
import React, { useCallback, useLayoutEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import {
  createApiClientRequestInterceptor,
  createApiClientResponseInterceptor,
  setApiAuthorizationHeader,
  setApiCompanyIdHeaders,
} from "../api/apiClient";
import {
  setAllUsersData,
  setDepartmentsData,
  setIsAuth,
  setPositionsData,
  setProjectsData,
  setRolesData,
  setUserData,
} from "../store/action";
import { useAppContext } from "../store";
import {
  getCompanyIdFromCookies,
  getTokenFromCookies,
  getUserIdFromCookies,
} from "../helpers/cookies";
import usersApi from "../api/usersApi";
import Loader from "../components/Loader";
import rolesApi from "../api/rolesApi";
import positionsApi from "../api/positionsApi";
import departmentsApi from "../api/departmentsApi";
import authApi from "../api/authApi";
import meetingApi from "../api/meetingApi";

interface Props {
  children: React.ReactNode;
}

const UserDataWrapper: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const { enqueueSnackbar } = useSnackbar();
  const { dispatch } = useAppContext();

  const [isLoading, setIsLoading] = useState(true);

  const getCurrentUser = useCallback(async () => {
    try {
      const { accessToken } = await authApi.refreshToken();
      const tokenFromCookies = getTokenFromCookies();
      const userId = getUserIdFromCookies();
      const companyId = getCompanyIdFromCookies();

      if (accessToken && tokenFromCookies) {
        setApiCompanyIdHeaders(companyId as string);
        setApiAuthorizationHeader(accessToken);
        createApiClientRequestInterceptor(() => dispatch(setIsAuth(false)));
        createApiClientResponseInterceptor(() => dispatch(setIsAuth(false)));

        const resp = await usersApi.getUserById(parseInt(userId as string, 10));
        const rolesResp = await rolesApi.getRolesList();
        const positionsResp = await positionsApi.getPositionsList();
        const departmentsResp = await departmentsApi.getDepartmentsList();
        const preCreateMeetingData = await meetingApi.getPreCreateMeetingData();

        dispatch(setUserData(resp.data));
        dispatch(setAllUsersData(preCreateMeetingData.users));
        dispatch(setProjectsData(preCreateMeetingData.projects));
        dispatch(setPositionsData(positionsResp));
        dispatch(setRolesData(rolesResp));
        dispatch(setDepartmentsData(departmentsResp));
        dispatch(setIsAuth(true));
      }
    } catch (e) {
      const error = e as AxiosError;
      if (error?.response?.status !== 404)
        enqueueSnackbar(error.response?.data?.message || error.message, {
          variant: "error",
        });
    }
  }, [dispatch, enqueueSnackbar]);

  useLayoutEffect(() => {
    getCurrentUser().then(() => setIsLoading(false));
  }, [getCurrentUser]);

  return isLoading ? (
    <Loader inline={false} height="100vh" />
  ) : (
    <div>{children}</div>
  );
};

export default UserDataWrapper;
