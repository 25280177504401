import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { string } from "yup/lib/locale";
import { getIsMenuCollapsed } from "../helpers/localStorage";
import { TableVariantEnum } from "../types/common";
import { MeetingTableTabsEnum } from "../types/Workflow";
import reducer from "./reducer";
import { AppStateContext, IAppContext } from "./types";

const isMenuCollapsedLs = getIsMenuCollapsed();

const initialState: AppStateContext = {
  idMeet: "6344f58c290c406b37a47c51",
  idNotice: "6344f58c290c406b37a47c5c",
  isAuth: false,
  tableVariant: TableVariantEnum.TABLE,
  isMenuCollapsed: isMenuCollapsedLs === "true",
  isDrawerOpen: false,
  meetingsTableVariant: MeetingTableTabsEnum.meetings,
  meetingsSort: undefined,
  tasksSort: undefined,
  usersSort: undefined,
  companyId: "",
  page: 1,
  perPage: 10,
  globalSearchQquery: undefined,
  globalSearchType: "",
  globalSearchResult: { count: undefined, rows: [] },
  projects: [],
  users: [],
  user: {
    id: 0,
    birthday: "",
    blocked: 0,
    departments: [],
    email: "",
    email_verified: 0,
    gender: "",
    name: "",
    patronymic: "",
    phone: "",
    positions: [],
    roles: [],
    surname: "",
    telegram_code: null,
    telegram_registered: 0,
    unique_link: null,
    work_groups: [],
    image: null,
    isHead:true
  },
  roles: {
    data: [
      {
        id: 0,
        name: "",
        rus_name: "",
      },
    ],
  },
  positions: {
    data: [],
    meta: {
      current_page: 0,
      from: 0,
      last_page: 0,
      path: "",
      per_page: 0,
      to: 0,
      total: 0,
    },
    links: {
      first: "",
      last: "",
      next: "",
      prev: null,
    },
  },
  departments: {
    data: [],
    links: {
      first: "",
      last: "",
      next: "",
      prev: null,
    },
    meta: {
      current_page: 0,
      from: 0,
      last_page: 0,
      path: "",
      per_page: 0,
      to: 0,
      total: 0,
    },
  },
};

export const AppContext = createContext<IAppContext>({
  state: initialState,
  dispatch: () => null,
});

export const useAppContext = (): IAppContext => useContext(AppContext);

interface Props {
  children: ReactNode;
}

export const AppStoreProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
