import PersonIcon from "@mui/icons-material/Person";
import { MeetingsIcon, UsersIcon, HouseIcon, TasksIcon } from "../icons";

const appLinks = {
  index: {
    name: "Home",
    link: "/",
  },
  login: {
    name: "Login",
    link: "/login",
  },
  profile: {
    name: "Профиль",
    link: "/profile",
    icon: PersonIcon,
  },
  workflow: {
    name: "Совещания",
    link: "/workflow",
    icon: MeetingsIcon,
  },
  shtat: {
    name: "Сотрудники",
    link: "/shtat",
    icon: UsersIcon,
  },
  company: {
    name: "Компания",
    link: "/company",
    icon: HouseIcon,
  },
  forgotPasword: {
    name: "Forgot Password",
    link: "/forgot",
  },
  payment: {
    name: "Тариф",
    link: "/payment",
  },
  register: {
    name: "Регистрация",
    link: "/register",
  },
  chooseCompany: {
    name: "Choose Company",
    link: "/company/choose",
  },
  meetingDetails: {
    name: "Meeting Details",
    link: "/meeting/:id",
    asLink: (id: string): string => `/meeting/${id}`,
  },
  meetingCreate: {
    name: "Create Meeting",
    link: "/meeting/create",
  },
  tasks: {
    name: "Задачи",
    link: "/tasks",
    icon: TasksIcon,
  },
  globalSearch: {
    name: "Глобальный Поиск",
    link: "/global",
  },
};

const MenuPrimaryLinks = [
  appLinks.workflow,
  appLinks.tasks,
  appLinks.shtat,
  appLinks.company,
];

export { appLinks, MenuPrimaryLinks };
