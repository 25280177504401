import { AppActionEnums, AppActions, AppStateContext } from "./types";

const reducer = (
  state: AppStateContext,
  action: AppActions
): AppStateContext => {
  switch (action.type) {
    case AppActionEnums.SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.payload,
      };
    case AppActionEnums.SET_ID_MEET:
        return {
          ...state,
          idMeet: action.payload,
        };  
    case AppActionEnums.SET_ID_NOTICE:
        return {
          ...state,
          idNotice: action.payload,
        };     
    case AppActionEnums.SET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case AppActionEnums.SET_USER_DATA:
      return {
        ...state,
        user: action.payload,
      };
    case AppActionEnums.SET_COMPANY_ID:
      return {
        ...state,
        companyId: action.payload,
      };
    case AppActionEnums.SET_ROLES_DATA:
      return {
        ...state,
        roles: action.payload,
      };
    case AppActionEnums.SET_POSITIONS_DATA:
      return {
        ...state,
        positions: action.payload,
      };
    case AppActionEnums.SET_DEPARTMENTS_DATA:
      return {
        ...state,
        departments: action.payload,
      };
    case AppActionEnums.TOGGLE_IS_MENU_COLLAPSED:
      return {
        ...state,
        isMenuCollapsed: !state.isMenuCollapsed,
      };
    case AppActionEnums.SET_DRAWER_OPEN:
      return {
        ...state,
        isDrawerOpen: action.payload,
      };
    case AppActionEnums.SET_MEETINGS_TABLE_VARIANT:
      return {
        ...state,
        meetingsTableVariant: action.payload,
      };
    case AppActionEnums.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case AppActionEnums.SET_PER_PAGE:
      return {
        ...state,
        perPage: action.payload,
      };
    case AppActionEnums.SET_GLOBAL_SEARCH_RESULT:
      return {
        ...state,
        globalSearchResult: action.payload,
      };
    case AppActionEnums.SET_GLOBAL_SEARCH_QUERY:
      return {
        ...state,
        globalSearchQquery: action.payload,
      };
    case AppActionEnums.SET_GLOBAL_SEARCH_TYPE:
      return {
        ...state,
        globalSearchType: action.payload,
      };
    case AppActionEnums.SET_PROJECTS_DATA:
      return {
        ...state,
        projects: action.payload,
      };
    case AppActionEnums.SET_TABLE_VARIANT:
      return {
        ...state,
        tableVariant: action.payload,
      };
    case AppActionEnums.SET_MEETINGS_SORT:
      return {
        ...state,
        meetingsSort: action.payload,
      };
    case AppActionEnums.SET_TASKS_SORT:
      return {
        ...state,
        tasksSort: action.payload,
      };
    case AppActionEnums.SET_USERS_SORT:
      return {
        ...state,
        usersSort: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
