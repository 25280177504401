import { RolesListResponse, RolesListData } from "../types/Roles";
import { apiClient } from "./apiClient";
import ENDPOINTS from "./endpoints";

const endpoint = ENDPOINTS.ROLES;

const getRolesList = (): RolesListResponse =>
  apiClient.get<RolesListData>(endpoint).then((res) => res.data);

export default { getRolesList };
