import Cookies from "js-cookie";

const COOKIES = {
  REFRESH_TOKEN: "easy_meeting_refresh_token",
  USER_ID: "user_id",
  COMPANY_ID: "company_id",
  EASY_TASK_REFRESH_TOKEN: "refreshToken",
};

const expiresDate = Date.now() + 180 * 60 * 1000;

const setTokenToCookies = (token: string): string | undefined =>
  Cookies.set(COOKIES.REFRESH_TOKEN, token, {
    expires: expiresDate,
  });

const getTokenFromCookies = (): string | undefined =>
  Cookies.get(COOKIES.REFRESH_TOKEN);

const removeTokenFromCookies = (): void =>
  Cookies.remove(COOKIES.REFRESH_TOKEN);

const setUserIdToCookies = (id: string): string | undefined =>
  Cookies.set(COOKIES.USER_ID, id);

const getUserIdFromCookies = (): string | undefined =>
  Cookies.get(COOKIES.USER_ID);

const removeUserIdFromCookies = (): void => Cookies.remove(COOKIES.USER_ID);

const setCompanyIdToCookies = (id: string): string | undefined =>
  Cookies.set(COOKIES.COMPANY_ID, id);

const getCompanyIdFromCookies = (): string | undefined =>
  Cookies.get(COOKIES.COMPANY_ID);

const removeCompanyIdFromCookies = (): void =>
  Cookies.remove(COOKIES.COMPANY_ID);

export {
  setTokenToCookies,
  getTokenFromCookies,
  removeTokenFromCookies,
  setUserIdToCookies,
  getUserIdFromCookies,
  removeUserIdFromCookies,
  setCompanyIdToCookies,
  getCompanyIdFromCookies,
  removeCompanyIdFromCookies,
};
