// eslint-disable-next-line
export interface ListData<Item = any> {
  data: Item[];
  links: {
    first: string;
    last: string;
    prev: null;
    next: string;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  };
}

export interface ApiPaginationParams {
  search?: string;
  page: number;
  sort?: string;
  order?: string;
  orderType?: string;
  id?: string;
  per_page?: number;
  limit?: number;
  q?: string;
}

// eslint-disable-next-line
export interface ApigetDataResponse<Item = any> {
  count: number;
  rows: Item[];
}

export enum TableVariantEnum {
  TABLE = "TABLE",
  CARDS = "CARDS",
}
