//import { CompanyInfoPlateAction } from './pages/Company/action_types';

import { combineReducers } from 'redux';
import commonReducer from '../common/commonReducer';
//import companyInfoPlateReducer from './pages/Company/reducer';
import paymentReducer from '../containers/CompanyDetails/reducer';
import { CommonState } from '../common/commonReducer';

//import { CompanyInfoPlateState } from './pages/Company/reducer';
import { PaymentState } from '../containers/CompanyDetails/reducer';

export type State = {
   commonInfo: CommonState;
 // companyInfoPlate: CompanyInfoPlateState;
  payment: PaymentState;
};

export const rootReducer = combineReducers({
  //commonInfo: commonReducer,
 // companyInfoPlate: companyInfoPlateReducer,
  payment: paymentReducer,
});
