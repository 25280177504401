import { NoticeItemData } from "./Notice";

export enum MeetingFormatEnum {
  ONLINE = "online",
  OFFLINE = "offline",
}

export enum MeetingStatusEnum {
  canceled = "Отменено",
  finished = "Состоялось",
  on_approval = "На согласовании",
  approved = "Согласовано",
  confirmed = "Подтверждено", //new
  in_progress = "В процессе",
}

export enum MeetingStatusNotSecretaryEnum {
  agreed = "Подтвердить участие",  //изанчально называлось Согласовать
  update_date = "Удобное время",
  declined = "Отказ от участия",
}

export interface MeetingUserItem {
  id: number;
  name: string;
  surname: string;
  image: {
    url?: string | null;
    name?: string;
  } | null;
  patronymic?: string;
}

export interface MeetingListItem {
  _id: string;
  name: string;
  initiator: MeetingUserItem;
  chairman: MeetingUserItem;
  secretary: MeetingUserItem;
  participants: MeetingUserItem[];
  startAt: string;
  format: string;
  status: string;
  tag: MeetingUserItem;
  tagMeeting: string;
  notices: NoticeItemData[];
  createdAt: string;
  updatedAt: string;
  venue: string;
  files: string[];
  points: string;
  comment: string;
}

export interface MeetingsListData {
  count: number;
  rows: MeetingListItem[];
}

export type MeetingsListResponse = Promise<MeetingsListData>;
export interface PointItem {
  id: string;
  value: string;
}

export interface PointCreateParams {
  points: PointItem[];
}

export interface TagItem {
  id: string | number;
  name: string;
}

export interface MeetingCreateParams {
  name: string;
  chairman: MeetingUserItem;
  secretary: MeetingUserItem;
  initiator: MeetingUserItem;
  participants: MeetingUserItem[];
  tag: TagItem;
  tagMeeting?: string;
  startAt: string | Date | null;
  format: string;
  venue: string;
  _id?: string;
  files?: string[];
  points?: string;
  comment : string;
  notices?: NoticeItemData[];
}

export interface TaskCreateParams {
  name: string;
  chairman: MeetingUserItem;
  secretary: MeetingUserItem;
  initiator: MeetingUserItem;
  participants?: MeetingUserItem[];
  tag: TagItem;
  tagMeeting: string;
  startAt: string | Date | null;
  format: string;
  venue: string;
  _id?: string;
  files?: string[];
  points: string;
  comment: string;
  nameTask: string;
  descriptionTask: string;
  beginTask: string | Date | null;
  endTask: string | Date | null;
  responsibleFromTask: MeetingUserItem;
  executorFromTask: MeetingUserItem;
  type?:"task";
}

export interface MeetingData {
  name: string;
  initiator: MeetingUserItem;
  chairman: MeetingUserItem;
  secretary: MeetingUserItem;
  participants: MeetingUserItem[];
  startAt: string;
  format: string;
  status: string;
  tag: MeetingUserItem;
  tagMeeting: string;
  notices: NoticeItemData[];
  _id: string;
  createdAt: string;
  updatedAt: string;
  venue: string;
  files: string[];
  points: string;
  comment: string;
}

export type MeetingResponse = Promise<MeetingData>;

export interface MeetingPreCreateUserItem {
  id: number;
  name: string;
  surname: string;
  patronymic?: string;
  image: {
    url: string;
    name: string;
  };
  isHead: boolean;
}

export interface MeetingPreCreateProjectItem {
  name: string;
  id: number;
}

export interface MeetingPreCreateData {
  me: MeetingPreCreateUserItem;
  users: MeetingPreCreateUserItem[];
  projects: MeetingPreCreateProjectItem[];
}

export type MeetingPreCreateResponse = Promise<MeetingPreCreateData>;
