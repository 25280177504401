import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const TasksIcon = (props: SvgIconProps): React.ReactElement => (
<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="54" height="54" rx="10" fill="white" fill-opacity="0.1"/>
<path d="M32.5 24L25.1666 31L21.5 27.5" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M37 16H17C16.4477 16 16 16.4477 16 17V37C16 37.5523 16.4477 38 17 38H37C37.5523 38 38 37.5523 38 37V17C38 16.4477 37.5523 16 37 16Z" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);

export default TasksIcon;
