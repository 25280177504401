import { ApigetDataResponse } from "./common";
import { MeetingUserItem } from "./Meeting";

export enum MeetingFiltersEnum {
  // chairman = "Председатель",
  startAt = "Начало",
  status = "Статус",
  name = "Наименование",
  format = "Формат", 
  tag = "Проект",
  clear = "Очистить",
}

export enum MeetingFiltersEnumFilter {
  // chairman = "Председатель",
  startAt ="Дата и время начала",  
  status = "Статус", 
  name = "Наименование", 
  format = "Формат проведения",
  venue = "Место", 
  tag = "Проект",
  initiator = "Инициатор",
  chairman = "Председатель",
  sekretar = "Секретарь", 
  participants = "Участники",
  clear = "Очистить",
}


export enum TasksFiltersEnum {
  autor = "Автор",
  name = "Наименование",
  status = "Статус",
  startAt = "Дата и время начала",  
  deadline ="Дедлайн",
  assigne = "Исполнитель",
  tag = "Проект",
  clear = "Очистить"
}



export enum HeaderFiltersEnum {
  // chairman = "Председатель",
  status = "Статус",
  name = "Наименование",
  format = "Формат", 
  place = "Место",
  startAt = "Дата начала",
  tag = "Проект",
  chairman = "Председатель",
  secretary = "Секретарь",
  members = "Участники",
}

export enum TasksRulesIdeasTableFIltersEnum {
  type = "Тип",
  // assignee = "Исполнитель",
  // responsible = "Ответственный",
  startAt = "Начало",
  endAt = "Конец",
  clear = "Очистить",
}

export interface MyWorkflowUserItem {
  id: number;
  name: string;
}

export interface TagItem {
  id: string | number;
  name: string;
}


export interface WorkflowCreateParams {
  name: string;
  description: string;
  type: string;
  assignee: MeetingUserItem;
  responsible: MeetingUserItem;
  startAt: Date | string | null;
  endAt: Date | string | null;
  timing: Date | string;
  _id?: string;
  meeting?: string;
  notice?: string;
  workflowId?: string;
  isSecretary?: boolean;
  meetingParticipants: MyWorkflowUserItem[];
  tag: TagItem;
}

export interface WorkflowListItem extends WorkflowCreateParams {
  meetingPadriticipants: MyWorkflowUserItem[];
}

export type WorkflowListData = ApigetDataResponse<WorkflowListItem>;

export type WorkflowListResponse = Promise<WorkflowListData>;

export interface MeetingUsersUpdateParams {
  secretary: MeetingUserItem;
  participants: MeetingUserItem[];
  status: string;
}

export enum MeetingTableTabsEnum {
  meetings = "meetings",
  workflows = "workflows",
}

export interface MyWorkflowListItem extends WorkflowCreateParams {
  isSecretary?: boolean;
  meeting?: string;
  notice?: string;
}

export type MyWorkflowListData = ApigetDataResponse<MyWorkflowListItem>;

export type MyWorkflowListResponse = Promise<MyWorkflowListData>;
