import {
  TasksRulesIdeasTableFIltersEnum,
  MeetingFiltersEnum,
} from "../types/Workflow";
import { TableVariantEnum } from "../types/common";
import { ProjectsListData } from "../types/Projects";
import { GlobalSearchData } from "../types/GlobalSearchResults";
import { DepartmentsListData } from "../types/Departments";
import { PositionsListData } from "../types/Positions";
import { RolesListData } from "../types/Roles";
import { UserData, UserPreCreateItem, UsersTableEnum } from "../types/Users";
import { AppActionEnums, AppActions } from "./types";

const setIsAuth = (isAuth: boolean): AppActions => ({
  type: AppActionEnums.SET_IS_AUTH,
  payload: isAuth,
});

const setIdMeet = (idMeet: string): AppActions => ({
  type: AppActionEnums.SET_ID_MEET,
  payload: idMeet,
});

const setIdNotice = (idNotice: string): AppActions => ({
  type: AppActionEnums.SET_ID_NOTICE,
  payload: idNotice,
});


const setUserData = (data: UserData): AppActions => ({
  type: AppActionEnums.SET_USER_DATA,
  payload: data,
});

const setCompanyId = (id: string): AppActions => ({
  type: AppActionEnums.SET_COMPANY_ID,
  payload: id,
});

const setRolesData = (data: RolesListData): AppActions => ({
  type: AppActionEnums.SET_ROLES_DATA,
  payload: data,
});

const setPositionsData = (data: PositionsListData): AppActions => ({
  type: AppActionEnums.SET_POSITIONS_DATA,
  payload: data,
});

const setDepartmentsData = (data: DepartmentsListData): AppActions => ({
  type: AppActionEnums.SET_DEPARTMENTS_DATA,
  payload: data,
});

const toggleIsMenuCollapsed = (): AppActions => ({
  type: AppActionEnums.TOGGLE_IS_MENU_COLLAPSED,
});

const setAllUsersData = (data: UserPreCreateItem[]): AppActions => ({
  type: AppActionEnums.SET_ALL_USERS,
  payload: data,
});

const setDrawerOpen = (data: boolean): AppActions => ({
  type: AppActionEnums.SET_DRAWER_OPEN,
  payload: data,
});

const setMeetingsTableVariant = (variant: string): AppActions => ({
  type: AppActionEnums.SET_MEETINGS_TABLE_VARIANT,
  payload: variant,
});

const setPage = (page: number): AppActions => ({
  type: AppActionEnums.SET_PAGE,
  payload: page,
});

const setPerPage = (perPage: number): AppActions => ({
  type: AppActionEnums.SET_PER_PAGE,
  payload: perPage,
});

const setGlobalSearchResult = (data: GlobalSearchData): AppActions => ({
  type: AppActionEnums.SET_GLOBAL_SEARCH_RESULT,
  payload: data,
});

const setGlobalSearchQuery = (query: string | undefined): AppActions => ({
  type: AppActionEnums.SET_GLOBAL_SEARCH_QUERY,
  payload: query,
});

const setGlobalSearchType = (globalSearchType: string): AppActions => ({
  type: AppActionEnums.SET_GLOBAL_SEARCH_TYPE,
  payload: globalSearchType,
});

const setProjectsData = (data: ProjectsListData): AppActions => ({
  type: AppActionEnums.SET_PROJECTS_DATA,
  payload: data,
});

const setTableVariant = (data: TableVariantEnum): AppActions => ({
  type: AppActionEnums.SET_TABLE_VARIANT,
  payload: data,
});

const SetTasksSort = (data: TasksRulesIdeasTableFIltersEnum): AppActions => ({
  type: AppActionEnums.SET_TASKS_SORT,
  payload: data,
});

const setMeetingsSort = (data: MeetingFiltersEnum): AppActions => ({
  type: AppActionEnums.SET_MEETINGS_SORT,
  payload: data,
});

const setUsersSort = (data: UsersTableEnum): AppActions => ({
  type: AppActionEnums.SET_USERS_SORT,
  payload: data,
});

export {
  setIsAuth,
  setIdMeet,
  setIdNotice,
  setUserData,
  setCompanyId,
  setRolesData,
  setPositionsData,
  setDepartmentsData,
  toggleIsMenuCollapsed,
  setAllUsersData,
  setDrawerOpen,
  setMeetingsTableVariant,
  setPage,
  setPerPage,
  setGlobalSearchResult,
  setGlobalSearchQuery,
  setGlobalSearchType,
  setProjectsData,
  setTableVariant,
  setMeetingsSort,
  SetTasksSort,
  setUsersSort,
};
