import axios, { AxiosInstance } from "axios";
import {
  removeCompanyIdFromCookies,
  removeTokenFromCookies,
  removeUserIdFromCookies,
} from "../helpers/cookies";
import { parseJwtToken } from "../helpers/coomon";
import { ParsedJwtToken } from "../types/Auth";
import authApi from "./authApi";
import ENDPOINTS from "./endpoints";

const LOG_OUT_URL = ENDPOINTS.LOG_OUT;

const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

const apiClientOurServer: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_OUR_URL,
  responseType: "json",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

const setApiAuthorizationHeader = (token: string): void => {
  apiClient.defaults.headers.Authorization = `Bearer ${token}`;
  apiClientOurServer.defaults.headers.Authorization = `Bearer ${token}`;
  
};

const setApiCompanyIdHeaders = (id: string): void => {
  apiClient.defaults.headers.common["company-id"] = id;
  apiClientOurServer.defaults.headers.common["company-id"] = id;
};

const deleteApiAuthorizationHeader = (): void => {
  delete apiClient.defaults.headers.Authorization;
  delete apiClientOurServer.defaults.headers.Authorization;
};

const deleteApiCompanyHeader = (): void => {
  delete apiClient.defaults.headers.company_id;
  delete apiClientOurServer.defaults.headers.company_id;
};

const createApiClientResponseInterceptor = (on401Error: () => void): void => {
  const interceptor = apiClient.interceptors.response.use(
    (value) => {
      if (value.config.url === LOG_OUT_URL) {
        apiClient.interceptors.response.eject(interceptor);
        apiClientOurServer.interceptors.response.eject(interceptor);
      }

      return Promise.resolve(value);
    },
    (error) => {
      if (error.response.status === 401 || error.response.status === 500) {
        on401Error();
        removeTokenFromCookies();

        return Promise.reject(error);
      }

      apiClient.interceptors.response.eject(interceptor);
      apiClientOurServer.interceptors.response.eject(interceptor);

      return Promise.reject(error);
    }
  );
  const interceptorOurServer = apiClientOurServer.interceptors.response.use(
    (value) => {
      if (value.config.url === LOG_OUT_URL) {
        apiClient.interceptors.response.eject(interceptor);
        apiClientOurServer.interceptors.response.eject(interceptor);
      }

      return Promise.resolve(value);
    },
    (error) => {
      if (error.response.status === 401 || error.response.status === 500) {
        on401Error();
        removeTokenFromCookies();

        return Promise.reject(error);
      }

      apiClient.interceptors.response.eject(interceptor);
      apiClientOurServer.interceptors.response.eject(interceptorOurServer);

      return Promise.reject(error);
    }
  );
};

const createApiClientRequestInterceptor = (on404Error: () => void): void => {
  const interceptor = apiClient.interceptors.request.use(
    async (value) => {
      if (value.url === LOG_OUT_URL) {
        apiClient.interceptors.request.eject(interceptor);
        apiClientOurServer.interceptors.request.eject(interceptor);
        removeTokenFromCookies();
        removeUserIdFromCookies();
        removeCompanyIdFromCookies();

        return Promise.resolve(value);
      }

      return Promise.resolve(value);
    },
    (error) => {
      if (
        error.response.status === 404 ||
        error.response.status === 401 ||
        error.response.status === 500
      ) {
        removeTokenFromCookies();
        removeUserIdFromCookies();
        on404Error();
        Promise.reject(error);
      }
    }
  );
  const interceptorOurServer = apiClientOurServer.interceptors.request.use(
    async (value) => {
      if (value.url === LOG_OUT_URL) {
        apiClient.interceptors.request.eject(interceptor);
        apiClientOurServer.interceptors.request.eject(interceptor);
        removeTokenFromCookies();
        removeUserIdFromCookies();
        removeCompanyIdFromCookies();

        return Promise.resolve(value);
      }

      if (value.headers.Authorization) {
        const parsedToken: ParsedJwtToken = parseJwtToken(
          value.headers.Authorization.split(" ")[1]
        );
        const expDate = new Date(parsedToken.exp * 1000);

        if (expDate < new Date()) {
          await authApi
            .refreshToken()
            .then((res) => {
              setApiAuthorizationHeader(res.accessToken);

              // eslint-disable-next-line no-param-reassign
              value.headers.Authorization = `Bearer ${res.accessToken}`;

              return value;
            })
            .catch((reason) => {
              if (
                reason.response.status === 404 ||
                reason.response.status === 500
              ) {
                apiClient.interceptors.request.eject(interceptorOurServer);
                apiClientOurServer.interceptors.request.eject(
                  interceptorOurServer
                );
                removeTokenFromCookies();
                on404Error();
              }
            });
        }
      }

      return Promise.resolve(value);
    },
    (error) => {
      if (
        error.response.status === 404 ||
        error.response.status === 401 ||
        error.response.status === 500
      ) {
        removeTokenFromCookies();
        removeUserIdFromCookies();
        on404Error();
        Promise.reject(error);
      }
    }
  );
};

export {
  apiClient,
  apiClientOurServer,
  setApiAuthorizationHeader,
  deleteApiAuthorizationHeader,
  createApiClientResponseInterceptor,
  createApiClientRequestInterceptor,
  setApiCompanyIdHeaders,
  deleteApiCompanyHeader,
};
