import { createTheme, Theme } from "@mui/material";
import colors from "./colors";

const createThemeComponent = (): Theme =>
  createTheme({
    palette: {
      primary: {
        main: colors.primary,
        light: "#53BEF0",
      },
      secondary: {
        main: colors.secondary,
      },
    },
    components: {
      MuiListItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              background: "none",
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: "#7BCBF0",
            },
            "&.Mui-selected": {
              "&:hover": {
                backgroundColor: "#7BCBF0",
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
            color: "#fff",
          },
          text: {
            color: colors.primary,
          },
        },
        defaultProps: {
          variant: "contained",
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "filled",
          fullWidth: true,
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: "filled",
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: "filled",
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            background: "none",
            "&.Mui-disabled": {
              background: "none",
            },
          },
        },
      },
    },
  });

export default createThemeComponent;
