import { PositionsListResponse, PositionsListData } from "../types/Positions";
import { apiClient } from "./apiClient";
import ENDPOINTS from "./endpoints";

const endpoint = ENDPOINTS.POSITIONS;

const getPositionsList = (): PositionsListResponse =>
  apiClient.get<PositionsListData>(endpoint).then((res) => res.data);

export default { getPositionsList };
