const LocalStorageEnum = {
  IS_MENU_COLLAPSED: "easy_meeting_menuCollapsed",
  TABLE_VARIANT: "easy_meeting_table_variant",
};

const getIsMenuCollapsed = (): string | null =>
  localStorage.getItem(LocalStorageEnum.IS_MENU_COLLAPSED);

const setIsMenuCollapsed = (value: boolean): void =>
  localStorage.setItem(LocalStorageEnum.IS_MENU_COLLAPSED, value.toString());

const setTableViewVariant = (value: string): void =>
  localStorage.setItem(LocalStorageEnum.TABLE_VARIANT, value);

const getTableViewVariant = (): string | null =>
  localStorage.getItem(LocalStorageEnum.TABLE_VARIANT);

export {
  getIsMenuCollapsed,
  setIsMenuCollapsed,
  getTableViewVariant,
  setTableViewVariant,
};
