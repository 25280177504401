import { Dispatch } from "react";
import {
  MeetingFiltersEnum,
  TasksRulesIdeasTableFIltersEnum,
} from "../types/Workflow";
import { TableVariantEnum } from "../types/common";
import { ProjectsListData } from "../types/Projects";
import { GlobalSearchData } from "../types/GlobalSearchResults";
import { DepartmentsListData } from "../types/Departments";
import { PositionsListData } from "../types/Positions";
import { UserData, UserPreCreateItem, UsersTableEnum } from "../types/Users";
import { RolesListData } from "../types/Roles";

export interface AppStateContext {
  idMeet: string;
  idNotice: string;
  isAuth: boolean;
  meetingsTableVariant: string;
  isMenuCollapsed: boolean;
  user: UserData;
  globalSearchQquery: string | undefined;
  globalSearchType: string;
  companyId: string;
  roles: RolesListData;
  positions: PositionsListData;
  departments: DepartmentsListData;
  isDrawerOpen: boolean;
  users: UserPreCreateItem[];
  page: number;
  perPage: number;
  globalSearchResult: GlobalSearchData;
  projects: ProjectsListData;
  tableVariant: TableVariantEnum;
  meetingsSort: MeetingFiltersEnum | undefined;
  tasksSort: TasksRulesIdeasTableFIltersEnum | undefined;
  usersSort: UsersTableEnum | undefined;
}

export interface IAppContext {
  state: AppStateContext;
  dispatch: Dispatch<AppActions>;
}

export enum AppActionEnums {
  SET_IS_AUTH = "SET_IS_AUTH",
  SET_ID_MEET = "SET_ID_MEET",
  SET_ID_NOTICE = "SET_ID_NOTICE",
  TOGGLE_IS_MENU_COLLAPSED = "TOGGLE_IS_MENU_COLLAPSED",
  SET_USER_DATA = "SET_USER_DATA",
  SET_COMPANY_ID = "SET_COMPANY_ID",
  SET_ROLES_DATA = "SET_ROLES_DATA",
  SET_PROJECTS_DATA = "SET_PROJECTS_DATA",
  SET_POSITIONS_DATA = "SET_POSITIONS_DATA",
  SET_DEPARTMENTS_DATA = "SET_DEPARTMENTS_DATA",
  SET_ALL_USERS = "SET_ALL_USERS",
  SET_DRAWER_OPEN = "SET_DRAWER_OPEN",
  SET_MEETINGS_TABLE_VARIANT = "SET_MEETINGS_TABLE_VARIANT",
  SET_PAGE = "SET_PAGE",
  SET_PER_PAGE = "SET_PER_PAGE",
  SET_GLOBAL_SEARCH_RESULT = "SET_GLOBAL_SEARCH_RESULT",
  SET_GLOBAL_SEARCH_QUERY = "SET_GLOBAL_SEARCH_QUERY",
  SET_GLOBAL_SEARCH_TYPE = "SET_GLOBAL_SEARCH_TYPE",
  SET_TABLE_VARIANT = "SET_TABLE_VARIANT",
  SET_MEETINGS_SORT = "SET_MEETINGS_SORT",
  SET_TASKS_SORT = "SET_TASKS_SORT",
  SET_USERS_SORT = "SET_USERS_SORT",
}

interface SetAllUsers {
  type: AppActionEnums.SET_ALL_USERS;
  payload: UserPreCreateItem[];
}

interface SetIsAuth {
  type: AppActionEnums.SET_IS_AUTH;
  payload: boolean;
}

interface SetIdMeet {
  type: AppActionEnums.SET_ID_MEET;
  payload: string;
}

interface SetIdNotice {
  type: AppActionEnums.SET_ID_NOTICE;
  payload: string;
}


interface ToggleIsMenuCollapsed {
  type: AppActionEnums.TOGGLE_IS_MENU_COLLAPSED;
}

interface SetUserData {
  type: AppActionEnums.SET_USER_DATA;
  payload: UserData;
}

interface SetCompanyId {
  type: AppActionEnums.SET_COMPANY_ID;
  payload: string;
}

interface SetRolesData {
  type: AppActionEnums.SET_ROLES_DATA;
  payload: RolesListData;
}

interface SetPositionsData {
  type: AppActionEnums.SET_POSITIONS_DATA;
  payload: PositionsListData;
}

interface SetDepartmentsData {
  type: AppActionEnums.SET_DEPARTMENTS_DATA;
  payload: DepartmentsListData;
}

interface SetDrawerOpen {
  type: AppActionEnums.SET_DRAWER_OPEN;
  payload: boolean;
}

interface SetMeetingsTableVariant {
  type: AppActionEnums.SET_MEETINGS_TABLE_VARIANT;
  payload: string;
}

interface SetPage {
  type: AppActionEnums.SET_PAGE;
  payload: number;
}

interface SetPerPage {
  type: AppActionEnums.SET_PER_PAGE;
  payload: number;
}

interface SetGlobalSearchReslult {
  type: AppActionEnums.SET_GLOBAL_SEARCH_RESULT;
  payload: GlobalSearchData;
}

interface SetGlobalSearchQuery {
  type: AppActionEnums.SET_GLOBAL_SEARCH_QUERY;
  payload: string | undefined;
}

interface SetGlobalSearchType {
  type: AppActionEnums.SET_GLOBAL_SEARCH_TYPE;
  payload: string;
}

interface SetProjectsData {
  type: AppActionEnums.SET_PROJECTS_DATA;
  payload: ProjectsListData;
}

interface SetTablevariant {
  type: AppActionEnums.SET_TABLE_VARIANT;
  payload: TableVariantEnum;
}

interface SetMeetingSort {
  type: AppActionEnums.SET_MEETINGS_SORT;
  payload: MeetingFiltersEnum;
}

interface SetTasksSort {
  type: AppActionEnums.SET_TASKS_SORT;
  payload: TasksRulesIdeasTableFIltersEnum;
}

interface SetUsersSort {
  type: AppActionEnums.SET_USERS_SORT;
  payload: UsersTableEnum;
}

export type AppActions =
  | SetIsAuth
  | SetIdMeet
  | SetIdNotice  
  | ToggleIsMenuCollapsed
  | SetUserData
  | SetCompanyId
  | SetRolesData
  | SetPositionsData
  | SetDepartmentsData
  | SetAllUsers
  | SetDrawerOpen
  | SetMeetingsTableVariant
  | SetPage
  | SetPerPage
  | SetGlobalSearchReslult
  | SetGlobalSearchQuery
  | SetGlobalSearchType
  | SetProjectsData
  | SetTablevariant
  | SetMeetingSort
  | SetTasksSort
  | SetUsersSort;
