import {
  ResetPasswordParams,
  ResetPasswordResponse,
  ResetPasswordData,
  ResetAndChangePasswordParams,
  ResetAndChangePasswordResponse,
  ResetAndChangePasswordData,
  AuthParams,
  AuthResponse,
  LogOutData,
  ChangePasswordParams,
  ChangePasswordData,
  CompanyData,
  RefreshTokenResponse,
  RefreshTokenData,
  AuthData,
} from "../types/Auth";
import { apiClient, apiClientOurServer } from "./apiClient";
import ENDPOINTS from "./endpoints";

const passwordEndpoint = ENDPOINTS.PASSWORD;
const sessionEndpoint = ENDPOINTS.SESSION;

const signIn = (model: AuthParams): AuthResponse =>
  apiClientOurServer
    // .post<AuthData>(`${sessionEndpoint}/login`, undefined, {
    //   headers: {
    //     Authorizarion: `Basic ${btoa(`${model.email}:${model.password}`)}`,
    //   },
    // })
    // .then((res) => res.data);
    .post<AuthData>(`${sessionEndpoint}/login`, model)
    .then((res) => res.data);
// fetch(`${process.env.REACT_APP_OUR_URL}${sessionEndpoint}/login`, {
//   credentials: "include",
//   method: "POST",
//   headers: {
//     Authorization: `Basic ${btoa(`${model.email}:${model.password}`)}`,
//     "Content-Type": "application/json",
//   },
//   body: JSON.stringify(model),
// }).then((res) => res.json());

const refreshToken = (): RefreshTokenResponse =>
  apiClientOurServer
    .post<RefreshTokenData>(`${sessionEndpoint}/refresh-token`)
    .then((res) => res.data);

const selectCompany = (companyId: number): Promise<CompanyData> =>
  apiClientOurServer
    .post<CompanyData>(`${sessionEndpoint}/select-company`, {
      company: companyId,
    })
    .then((res) => res.data);

const logOut = (): Promise<LogOutData> =>
  apiClientOurServer.post(`${sessionEndpoint}/logout`).then((res) => res.data);

const changePassword = (
  params: ChangePasswordParams
): Promise<ChangePasswordData> =>
  apiClient
    .post<ChangePasswordData>(`${passwordEndpoint}/simple-change`, params)
    .then((res) => res.data);

const resetPassword = (params: ResetPasswordParams): ResetPasswordResponse =>
  apiClient
    .post<ResetPasswordData>(`${passwordEndpoint}/reset`, { email: params })
    .then((res) => res.data);

const resetAndChangePassword = (
  params: ResetAndChangePasswordParams
): ResetAndChangePasswordResponse =>
  apiClient
    .post<ResetAndChangePasswordData>(`${passwordEndpoint}/change`, params)
    .then((res) => res.data);

export default {
  signIn,
  logOut,
  changePassword,
  resetPassword,
  resetAndChangePassword,
  selectCompany,
  refreshToken,
};
