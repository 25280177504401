import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAppContext } from "../store";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { appLinks } from "./routes";

const Login = lazy(() => import("../containers/Login"));
const Layout = lazy(() => import("../app/Layout"));
const ForgotPassword = lazy(() => import("../containers/Login/ForgotPassword"));
const Register = lazy(() => import("../containers/Login/Register"));

const RouterWrapper: React.FC = () => {
  const {
    state: { isAuth },
  } = useAppContext();

  return (
    <Suspense fallback={<div>Loading</div>}>
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={
              <PrivateRoute isAuthenticated={isAuth}>
                <Layout />
              </PrivateRoute>
            }
          />
          <Route
            path={appLinks.login.link}
            element={
              <PublicRoute isAuthenticated={isAuth}>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path={appLinks.forgotPasword.link}
            element={
              <PublicRoute isAuthenticated={isAuth}>
                <ForgotPassword />
              </PublicRoute>
            }
          />
           <Route
            path={appLinks.register.link}
            element={
              <PublicRoute isAuthenticated={isAuth}>
                <Register />
              </PublicRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default RouterWrapper;
