//!!!!!!!!!не изменять структуру и порядок, только содержимое
export const columnsOfTables = [
    {
        columnId: 'id',
        showingText: '№',
        style: {minWidth: '40px'},
    },
    {
        columnId: 'author_id',
        showingText: 'Автор',
        style: {minWidth: '56px'},
    },
    {
        columnId: 'name',
        showingText: 'Задача',
        style: {minWidth: '256px'},
    },
    {
        columnId: 'status_id',
        showingText: 'Статус',
        style: {minWidth: '120px'},
    },
    {
        columnId: 'end',
        showingText: 'Дедлайн',
        style: {minWidth: '88px'},
    },
    {
        columnId: 'executor_id',
        showingText: 'Исп.',
        style: {minWidth: '56px'},
    },
    {
        columnId: 'task_load',
        showingText: 'Загрузка',
        style: {minWidth: '56px'},
    },

    {
        columnId: 'project_name',
        showingText: 'Проект',
        style: {minWidth: '160px'},
    },
    //   {
    //     columnId: 'section_name',
    //     showingText: 'Раздел',
    //     style: { minWidth: '40px' },
    //     className: 'section_col a_center',
    //   },
    {
        columnId: 'priority_id',
        showingText: 'Приоритет',
        style: {minWidth: '80px'},
    },
];

////////// shared functions for Saga in tasks and Projects
export function sorting(tasksOrder) {
    if (tasksOrder.col_name === '') {
        return '';
    } else {
        let result = '&order=' + tasksOrder.col_name;
        if (!tasksOrder.order_direct) result += '&orderType=desc';
        return result;
    }
}

export function table_filter(tableFilter) {
    return tableFilter ? '&search=' + tableFilter : '';
}

export function CollectAllFilters(filters) {
    // console.log(filters)
    let collect_all_values = '';
    if (filters.statuses) {

        let statuses_bank = ''
        filters.statuses.forEach((item) => {
            // и значения в конечном стринге разделяю запятыми
            if (statuses_bank.length) statuses_bank += ',';
            statuses_bank += item;
        });
        if (statuses_bank.length) collect_all_values += ('&status_id=' + statuses_bank);


        let authors_bank = ''
        filters.authors.forEach((item) => {
            // и значения в конечном стринге разделяю запятыми
            if (authors_bank.length) authors_bank += ',';
            authors_bank += item;
        });
        if (authors_bank.length) collect_all_values += ('&author_id=' + authors_bank);


        let executors_bank = ''
        filters.executors.forEach((item) => {
            // и значения в конечном стринге разделяю запятыми
            if (executors_bank.length) executors_bank += ',';
            executors_bank += item;
        });
        if (executors_bank.length) collect_all_values += ('&executor_id=' + executors_bank);

        if (filters.date_from.length) {
            collect_all_values += ('&begin_from=' + filters.date_from);
        }

        if (filters.date_to.length) {
            collect_all_values += ('&end_to=' + filters.date_to);
        }

    }
    return collect_all_values


    // if (Object.keys(filters.appliedFilters).length) {
    //   // если это фильтр с айдишником 1 - это фильтр по статусу
    //   if (Object.keys(filters.appliedFilters).includes('1')) {
    //     let collect_all_values = '';
    //     // я не знаю одно ли там значение или массив из нескольких, поэтому для гарантии прохожу циклом по всему массиву
    //     Object.values(filters.appliedFilters).forEach((item) => {
    //       // и значения в конечном стринге разделяю запятыми
    //       if (collect_all_values.length) collect_all_values += ',';
    //       collect_all_values += item;
    //     });
    //     return '&status_id=' + collect_all_values;
    //   }
    // } else return '';
}
