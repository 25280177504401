import { UserData } from "../types/Users";
import { ApiPaginationParams, ListData } from "../types/common";
import {
  DepartmentsListResponse,
  DepartmentsListData,
} from "../types/Departments";
import { apiClient } from "./apiClient";
import ENDPOINTS from "./endpoints";

const endpoint = ENDPOINTS.DEPARTMENTS;
const usersEndpoint = ENDPOINTS.USERS;

const getDepartmentsList = (): DepartmentsListResponse =>
  apiClient.get<DepartmentsListData>(endpoint).then((res) => res.data);

const getUsersListForDepartment = (
  departmentId: number,
  params: ApiPaginationParams
): Promise<ListData<UserData>> =>
  apiClient
    .get(!departmentId ? usersEndpoint : `${endpoint}/${departmentId}/users`, {
      params,
    })
    .then((res) => res.data);

export default { getDepartmentsList, getUsersListForDepartment };
