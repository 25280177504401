import { takeEvery, all, call, put } from "redux-saga/effects";
import {
  types,
  updateTaskAction,
  fetchExistingUserDataAction,
} from "./action_types";
import { fetchData } from "../utils/fetchData";
import { store } from "../app/App";

;
import { setCurrentUserInfo, change_remote_notifications } from "./actions";
import { User } from "./types";
import { da } from "date-fns/locale";
import { cookieMaster } from "../utils/CookieMaster";


/*
export function* watchNotificationsRead() {
  // @ts-ignore
  yield takeEvery(types.SET_NOTIFICATION_READ, setNotifRead);
}
*/

/*
export function* watchLoadNextPage() {
  // @ts-ignore
  yield takeEvery(types.SET_LOAD_NEXTPAGE_NOTIFICATIONS, loadNextpage);
}

*/

async function getAllProjects() {
  let page = 1,
    projects: any = [];
  while (true) {
    let data: any = await fetchData.get(`/api/v1/projects?page=${page}`);
    if (data) {
      projects = projects.concat(data["data"]);
      if (!data?.links?.next) break;
      page++;
    }
  }
  return projects;
}

async function getAllSections() {
  let page = 1,
    sections: any = [];
  while (true) {
    let data: any = await fetchData.get(`/api/v1/projectsections?page=${page}`);
    if (data) {
      sections = sections.concat(data["data"]);
      if (!data?.links?.next) break;
      page++;
    }
  }
  return sections;
}




