import { ApiPaginationParams } from "../types/common";
import {
  UserDataResponse,
  UsersListData,
  UserUpdateParams,
} from "../types/Users";
import { apiClient } from "./apiClient";
import ENDPOINTS from "./endpoints";

const endpoint = ENDPOINTS.USERS;

const getUserById = (id: number): UserDataResponse =>
  apiClient.get(`${endpoint}/${id}`).then((res) => res.data);

const getUsersList = (params: ApiPaginationParams): Promise<UsersListData> =>
  apiClient.get(endpoint, { params }).then((res) => res.data);

const updateCurrentUser = (
  params: UserUpdateParams,
  id: number
): UserDataResponse =>
  apiClient.patch(`${endpoint}/${id}`, params).then((res) => res.data);

export default { getUserById, getUsersList, updateCurrentUser };
