import {all} from 'redux-saga/effects';


import {
 //   watchNotificationsRead,
 //   watchLoadNextPage,
} from '../common/saga';




export function* rootSaga() {
    yield all([


     
   //     watchNotificationsRead(),
  //      watchLoadNextPage(),
    
     
    ]);
}
