import React from "react";
import { Navigate, RouteProps } from "react-router-dom";
import { appLinks } from "./routes";

interface Props extends RouteProps {
  isAuthenticated: boolean;
  children: React.ReactElement;
}

const PrivateRoute: React.FC<Props> = ({
  isAuthenticated,
  children,
}: Props) => {
  return isAuthenticated ? children : <Navigate to={appLinks.login.link} />;
};

export default PrivateRoute;
