import { MeetingUsersUpdateParams } from "../types/Workflow";
import { ApiPaginationParams } from "../types/common";
import {
  MeetingCreateParams,
  MeetingResponse,
  MeetingData,
  MeetingsListResponse,
  MeetingsListData,
  MeetingPreCreateResponse,
  MeetingPreCreateData,
} from "../types/Meeting";

import {
  NoticeCreateParams,
  NoticeItemResponse,
  NoticeItemData,
  NoticesListResponse,
  NoticesListData,
} from "../types/Notice";

import {
  CreateCommentParams,
  CommentResponse,
  CommentData,
} from "../types/Comments";

import { apiClientOurServer } from "./apiClient";
import ENDPOINTS from "./endpoints";
import { useAppContext } from "../store";
import {useState} from "react"

import { useContext } from "react";
import { useStateContext } from "../store/useStateContext";


/*
  const {
    state: { users },
  } = useAppContext();*/





let idMeet="";
let idNotice="";
let idComment="";

const endpoint = ENDPOINTS.MEETINGS;

const endpointNotice = ENDPOINTS.NOTICES;

const endpointComment = ENDPOINTS.COMMENTS;

const getMeetingsList = (
 textMeetsFilter,
  params: ApiPaginationParams
  ): MeetingsListResponse =>
  apiClientOurServer
    .get<MeetingsListData>(`${endpoint}?${textMeetsFilter}`, { params })
    .then((res) => res.data);

const getPreCreateMeetingData = (): MeetingPreCreateResponse =>
  apiClientOurServer
    .get<MeetingPreCreateData>(`${endpoint}/pre-create`)
    .then((res) => res.data);

const createMeeting = (params: MeetingCreateParams): MeetingResponse =>
  apiClientOurServer
    .post<MeetingData>(endpoint, params)
    .then((res) => {
      res.data; 
      idMeet = res.data["_id"];
   //  console.log("res.data",res.data);
    
    });

const updateMeeting = (
  params: MeetingCreateParams,
  id: string
): MeetingResponse =>
  apiClientOurServer
    .put<MeetingData>(`${endpoint}/${id}`, params)
    .then((res) => {
      res.data; 
      idMeet = res.data["_id"];
      idNotice = res.data.notices[0]["_id"];
    // console.log("res.data",res.data);
    
    });

    const updateMeetingUsers = (
      meetingId: string,
      params: MeetingUsersUpdateParams
    ): MeetingResponse =>
      apiClientOurServer
        .put<MeetingData>(`${endpoint}/${meetingId}/chairman`, params)
        .then((res) => res.data);

const updateMeetingUsers2 = (
  params: MeetingUsersUpdateParams
): MeetingResponse =>
  apiClientOurServer
    .put<MeetingData>(`${endpoint}/${idMeet}/chairman`, params)
    .then((res) => res.data);    

    const createNotice = (
      params: NoticeCreateParams,
    ): NoticeItemResponse =>
      apiClientOurServer
        .post<NoticeItemData>(`${endpointNotice}/${idMeet}`, params)
        .then((res) => res.data);    

        const updateNotice = (
          params: NoticeCreateParams,
        ): NoticeItemResponse =>
          apiClientOurServer
            .put<NoticeItemData>(`${endpointNotice}/${idMeet}/${idNotice}`, params)
            .then((res) => res.data);   
 
        const getNoticesListByMeetingId = (
          params: ApiPaginationParams,
        ): NoticesListResponse =>
          apiClientOurServer
            .get<NoticesListData>(`${endpointNotice}/${idMeet}`, { params })
            .then((res) => res.data);

            const createComment = (
              params: CreateCommentParams,
            ): CommentResponse =>
              apiClientOurServer
                .post<CommentData>(`${endpointComment}/${idMeet}`, params)
                .then((res) => res.data);
            
            const updateComment = (
              params: CreateCommentParams,
            ): CommentResponse =>
              apiClientOurServer
                .put<CommentData>(`${endpointComment}/${idMeet}/${idComment}`, params)
                .then((res) => res.data);     
            

const deleteMeeting = (id: string): Promise<void> =>
  apiClientOurServer.delete(`${endpoint}/${id}`).then((res) => res.data);

export default {
  getMeetingsList,
  getPreCreateMeetingData,
  createMeeting,
  updateMeeting,
  updateMeetingUsers,
  updateMeetingUsers2, 
  deleteMeeting,
  createNotice,
  getNoticesListByMeetingId,
  updateNotice,
  createComment,
  updateComment,
  idMeet,
  idNotice,
  idComment
};
