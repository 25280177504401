import { ParsedJwtToken } from "../types/Auth";

const getTrimmedDescription = (d: string): string =>
  d.length > 28 ? `${d.substr(0, 28)}...` : d;

const getTrimmedDescriptionForWorkflowCard = (d: string): string =>
  d.length > 100 ? `${d.substr(0, 100)}...` : d;

const getTrimmedName = (d: string): string =>
  d.length > 50 ? `${d.substr(0, 50)}...` : d;

const getTrimmedNameForUserSelectList = (d: string): string =>
  d.length > 10 ? `${d.substr(0, 10)}...` : d;

const parseJwtToken = (token: string): ParsedJwtToken => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export {
  getTrimmedDescription,
  getTrimmedDescriptionForWorkflowCard,
  getTrimmedName,
  getTrimmedNameForUserSelectList,
  parseJwtToken,
};
