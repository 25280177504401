import EventIcon from "@mui/icons-material/Event";
import BusinessIcon from "@mui/icons-material/Business";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DescriptionIcon from "@mui/icons-material/Description";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import SortIcon from "@mui/icons-material/Sort";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import PersonIcon from "@mui/icons-material/Person";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterList from "@mui/icons-material/FilterList";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimerIcon from "@mui/icons-material/Timer";
import TaskIcon from "@mui/icons-material/Task";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import WebIcon from "@mui/icons-material/Web";
import EditIcon from "@mui/icons-material/Edit";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import KeyIcon from "@mui/icons-material/Key";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

import CloseIcon from "./CloseIcon";
import EyeOpenIcon from "./EyeOpenIcon";
import EyeClosedIcon from "./EyeClosedIcon";
import UserIcon from "./UserIcon";
import MeetingsIcon from "./MeetingsIcon";
import TasksIcon from "./TasksIcon";
import UsersIcon from "./UsersIcon";
import HouseIcon from "./HouseIcon";
import TableIcon from "./TableIcon";
import CardsIcon from "./CardsIcon";














export {
  CloseIcon,
  EyeOpenIcon,
  EyeClosedIcon,
  UserIcon,
  EventIcon,
  KeyIcon,
  EmailIcon,
  PhoneIcon,
  BusinessCenterIcon,
  BusinessIcon,
  ContactPhoneIcon,
  ContactMailIcon,
  DescriptionIcon,
  WebIcon,
  AccountBalanceIcon,
  LocationOnIcon,
  EditIcon,
  SaveIcon,
  DeleteIcon,
  ArrowDropUpIcon,
  ExpandMoreIcon,
  PersonIcon,
  SupervisedUserCircleIcon,
  TaskIcon,
  MoreTimeIcon,
  AccessTimeIcon,
  TimerIcon,
  MoreVertIcon,
  FilterAltIcon,
  FilterList,
  SortIcon,
  FilePresentIcon,
  LogoutIcon,
  FileUploadIcon,
  HomeIcon,
  PlayArrowIcon,
  PauseIcon,
  MeetingsIcon,
  TasksIcon,
  UsersIcon,
  HouseIcon,
  TableIcon,
  CardsIcon,
};
